import loadable from "@loadable/component";
import classnames from "classnames";
import { isLeft } from "fp-ts/lib/Either";
import { inject, observer } from "mobx-react";
import { Fragment, useEffect } from "react";

import { RemoteList } from "../../components/remote/RemoteList.component";
import { FeedBlockValueResolver, removePrefix } from "@ihr-radioedit/inferno-core";
import type { BlockFragment, FeedResultFragment } from "@ihr-radioedit/inferno-webapi";
import { isAudienceFeedBlock, isCalendarFeedBlock } from "../../lib/guards";
import { getPaths } from "@ihr-radioedit/inferno-core";
import { ILog } from "@ihr-radioedit/inferno-core";
import { lookup } from "@ihr-radioedit/inferno-core";
import { isFeedBlock, reverseRoute } from "@inferno/renderer-shared-core";
import { getDatasourceBySlug } from "@ihr-radioedit/inferno-core";
import { FeedDisplayHints, getDisplayHints, isMeetTheShowBlock, isPodcastFeed } from "../../services/Sites.utils";
import { Container, LoadMoreFromCursor } from "../../ui";
import { MagicLink } from "@inferno/renderer-shared-ui";
import type { PageBlockInterface } from "../Block.component";
import { NoFeedItemMessage } from "./NoFeedItemMessage.component";
import { NoCalendarFeedItemMessage } from "./NoCalendarFeedItemMessage.component";
import { NoAudienceFeedItemMessage } from "./NoAudienceFeedItemMessage.component";

import { CalendarDatasourceMenu } from "./CalendarDatasourceMenu.component";
import type { Store } from "@inferno/renderer-shared-core";
const MeetTheShow = loadable(() => import("../meettheshow/MeetTheShow.component"));
const Datasource = loadable(() => import("./Datasource.component"));
const CalendarDatasource = loadable(() => import("./CalendarDatasource.component"));
const AudienceDatasource = loadable(() => import("./AudienceDatasource.component"));

const log = ILog.logger("DatasourceLoader.component.tsx");

export interface DatasourceLoaderProps extends PageBlockInterface {
  store?: Store;
  last_media_id?: string | null;
  topic_title?: string | null;
}

const getNoItemMessage = (block: BlockFragment, messageForNoResult?: boolean) => {
  if (messageForNoResult) {
    if (isAudienceFeedBlock(block)) {
      return <NoAudienceFeedItemMessage />;
    }

    if (isCalendarFeedBlock(block)) {
      return (
        <div className="component-container component-feed block">
          <CalendarDatasourceMenu />
          <NoCalendarFeedItemMessage />
        </div>
      );
    }

    return <NoFeedItemMessage />;
  }

  return null;
};

const getDatasource = (
  block: BlockFragment,
  data: FeedResultFragment[],
  displayHints: FeedDisplayHints,
  title = "",
  feedId = "",
) => {
  if (isAudienceFeedBlock(block)) {
    return (
      <AudienceDatasource title={title} content={data} block={block} feedId={feedId} displayHints={displayHints} />
    );
  }

  if (isCalendarFeedBlock(block)) {
    return (
      <CalendarDatasource title={title} content={data} block={block} feedId={feedId} displayHints={displayHints} />
    );
  }

  return <Datasource title={title} content={data} block={block} feedId={feedId} displayHints={displayHints} />;
};

export const DatasourceLoader = inject("store")(
  observer(({ store, block, isPrimary, topic_title }: DatasourceLoaderProps) => {
    if (!store) {
      return null;
    }
    const { site, cache, microsite } = store;
    const displayHints = getDisplayHints(block.tags || []);
    const { hints } = displayHints;
    const messageForNoResult = hints?.includes("display-hints/message-for-no-results");
    const noMessage = getNoItemMessage(block, messageForNoResult);

    if (!isFeedBlock(block)) {
      log.error("No Feed on block", block);
      return noMessage;
    }

    // Decode block value
    const decoded = FeedBlockValueResolver.decode(block.value);
    if (isLeft(decoded)) {
      log.error("Could not decode feed block", getPaths(decoded), block);
      return noMessage;
    }
    const { right: blockValue } = decoded;

    const feedId = blockValue.feed_id;

    if (!feedId) {
      log.error("No feed id or default feed setup!", block);
      return noMessage;
    }

    if (isMeetTheShowBlock(block.tags)) {
      return <MeetTheShow block={block} isPrimary={isPrimary} blockValue={blockValue} />;
    }

    // Hide if there are no results
    if (!block.feed?.results.length) {
      return noMessage;
    }

    // Fetch all required data
    const feedCursor = getDatasourceBySlug(
      cache,
      { ...block.feed },
      feedId,
      block.id,
    )({
      slug: microsite?.index?.slug || site.index.slug,
      lookup,
    });

    const feedClass = classnames("component-feed", {
      [`feed-style-${displayHints.style}`]: displayHints.style,
      "podcasts-feed": isPodcastFeed(block.tags || []),
      "themed-block": hints?.includes("display-hints/themed-block"),
    });

    // Call store block for tagging
    useEffect(() => {
      if (block?.feed) {
        store.storeBlock({
          ...block,
          resolved: {
            value: blockValue,
            feed: block.feed,
            topic_title: topic_title || "",
          },
        });
      }
    });

    return (
      <Container data-test-type={block.type} className={feedClass} data-feed={feedId}>
        <RemoteList
          cursor={feedCursor}
          showLoading={isPrimary}
          fallback={
            <Datasource
              title={blockValue.title || ""}
              content={block.feed.results}
              block={block}
              feedId={feedId}
              displayHints={displayHints}
            />
          }
        >
          {({ data, cursor, next, loading, hasNext }) => {
            let url = "";
            const { resume } = cursor?.options();
            if (hasNext && resume) {
              const { "<topic>": topic } = resume.context;
              const { from } = resume;
              if (topic && from) {
                url =
                  reverseRoute(store?.site, "topic_load_more", {
                    topic: removePrefix(/^\w+\//)(topic),
                    from,
                  }) || "#";
              }
            }

            const dataSource = getDatasource(block, data, displayHints, blockValue.title || "", feedId);

            return (
              <Fragment>
                {dataSource}
                {hasNext && displayHints.enableLoadMore ? (
                  <LoadMoreFromCursor hasNext={hasNext} nextFn={next} loading={loading} sectionName="feed" />
                ) : null}
                {url ? (
                  <MagicLink rel="next" className="next-page" to={url} target="_self">
                    Next Page
                  </MagicLink>
                ) : null}
              </Fragment>
            );
          }}
        </RemoteList>
      </Container>
    );
  }),
);

export default DatasourceLoader;
