import { getTag, LANGUAGES, removePrefix } from "@ihr-radioedit/inferno-core";
import { inject } from "mobx-react";
import { useTranslation } from "react-i18next";
import type { Store } from "@inferno/renderer-shared-core";
import { Remote } from "../../components/remote/Remote.component";
import { reverseRoute } from "@inferno/renderer-shared-core";
import { MagicLink } from "../../ui";
import styles from "./CalendarDatasourceMenu.module.scss";

interface CalendarDatasourceMenuProps {
  store?: Store;
}
export const CalendarDatasourceMenu = inject("store")(({ store }: CalendarDatasourceMenuProps) => {
  const { t } = useTranslation();
  if (!store) {
    return null;
  }

  const { site, routeParams, getSdkOpts } = store;

  if (!site.sections.general?.default_event_categories) {
    return null;
  }

  const tags = site.sections.general?.default_event_categories ?? [];
  const allEventsUrl = reverseRoute(site, "calendar_content_all") ?? "";
  const allEventsActiveClass = !routeParams?.topic ? styles.active : "";
  const locale = site.sections.general?.locale?.taxo?.name ?? LANGUAGES.English;

  return (
    <nav className={styles.componentCalendarMenu}>
      <ul>
        {allEventsUrl ? (
          <li>
            <MagicLink to={allEventsUrl} target={"_top"} className={allEventsActiveClass}>
              {t("all_events")}
            </MagicLink>
          </li>
        ) : null}
        {tags
          .filter(tag => tag.taxo?.displayName)
          .map((tag, i) => {
            if (!tag?.taxo?.memberName) {
              return null;
            }

            const topic = removePrefix(/^[\w-]+\//)(tag?.taxo?.memberName);

            const activeClass = topic === routeParams?.topic ? styles.active : "";

            const url = reverseRoute(site, "calendar_content_category", {
              topic,
            });

            if (!url) {
              return null;
            }
            return (
              <li key={i}>
                <Remote
                  loader={() => getTag(tag?.taxo?.memberName ?? "", locale, getSdkOpts())}
                  cacheKey={`calendar-category-${topic}-${locale.toLowerCase()}`}
                >
                  {({ data }) => {
                    return data ? (
                      <MagicLink to={url} target={"_top"} className={activeClass}>
                        {data.displayName}
                      </MagicLink>
                    ) : null;
                  }}
                </Remote>
              </li>
            );
          })}
      </ul>
    </nav>
  );
});
