import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Container } from "../../ui";

export const NoAudienceFeedItemMessage = inject("store")(
  observer(() => {
    const { t } = useTranslation();
    return (
      <Container>
        <p>{t("no_results_on_rules_page")}</p>
      </Container>
    );
  }),
);
